<template>
  <div>
    <!-- History Detail (Order Items) -->
    <div class="orderHistory-box rounded-xl my-4">
      <v-row no-gutters>
        <v-col cols="12" class="pa-4">
          <div class="font-weight-bold text-sm-body-1 text-body-2">
            {{ $t(`label.yourItems`) }}
          </div>
        </v-col>
        <v-col cols="12">
          <v-divider class="orderHistory-info-divider"></v-divider>
        </v-col>
        <v-col cols="12" class="pa-4">
          <v-row v-for="(orderItem, index) in detail.orderItems" justify="space-between" no-gutters :key="`${detail.referenceNumber}_${index}_${orderItem.product_display_sku}`">
            <v-col cols="12" sm="auto" class="text-sm-left text-center">
              <table class="orderHistory-info-table">
                <tr>
                  <td class="history-table-left-info product-image d-block d-sm-table-cell mx-auto mr-sm-0">
                    <img
                      class="full-width history-product-image-box rounded-lg"
                      :src="`${shared.MEDIA_SERVER_URL}/products/variants/${orderItem.product_display_sku}.png`"
                      :alt="orderItem.product_name + ' ' + getProductVariantDisplay(orderItem.product_variants)"
                      :title="orderItem.product_name + ' ' + getProductVariantDisplay(orderItem.product_variants)"
                    />
                  </td>
                  <td class="d-block d-sm-table-cell">
                    <p class="text-body-1 font-weight-bold my-1">{{ orderItem.product_name }}</p>
                    <p class="text-sm-body-1 text-body-2 my-1">
                      {{ getProductVariantDisplay(orderItem.product_variants) }}
                    </p>
                    <p class="unbox_red--text font-weight-bold text-sm-body-1 text-body-2 my-1">{{ `${$shared.formatCurrency(orderItem.purchase_price + orderItem.mark_up_price)}` }}</p>
                  </td>
                </tr>
              </table>
            </v-col>
            <v-col cols="12" sm="auto" class="text-sm-left text-center">
              <span class="text-sm-body-1 text-body-2">{{ `${$t(`label.quantity`)}: ` }}</span>
              <span class="text-h6 font-weight-bold mb-2">{{ `x${orderItem.quantity}` }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <!-- History Detail (Payment Method) -->
    <div>
      <div class="px-4">
        <div class="font-weight-bold text-body-2">
          <v-icon v-text="'$paymentMethodIcon'" class="stroke--unbox_primary text-body-1"></v-icon>
          {{ $t(`label.paymentMethod`) }}
        </div>

        <div class="mt-1 text-sm-body-1 text-body-2" v-if="detail.paymentInfo.length > 0">
          {{ $t(`paymentMethods.${detail.paymentInfo[0].payment_method.toLowerCase()}`) }}
        </div>
        <div class="mt-1 text-sm-body-1 text-body-2" v-else>-</div>
      </div>
      <v-divider class="my-2"></v-divider>
    </div>
    <!-- History Detail (Other Info) -->
    <div class="px-4">
      <table class="orderHistory-info-table">
        <tr>
          <td class="history-table-left-info d-sm-table-cell d-block">
            <p class="mb-0 text-sm-body-1 text-body-2 text_light_gray--text">{{ $t(`label.orderId`) }}</p>
          </td>
          <td class="d-sm-table-cell d-block">
            <p class="mb-0 text-sm-body-1 text-body-2">{{ detail.orderReferenceNumber }}</p>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import { SHARED, DELIVERY_METHOD } from '@/constants'
export default {
  props: {
    detail: {
      type: Object,
      required: true,
      default: null
    }
  },
  data() {
    return {
      shared: SHARED,
      deliveryMethod: DELIVERY_METHOD
    }
  },
  methods: {
    getProductVariantDisplay(variant) {
      return variant
        .map((o) => {
          return o.value
        })
        .join('/')
    },
    calculateSubtTotal() {
      if (this.detail === null || this.detail.orderItems.length <= 0) {
        return 0
      } else {
        let subtotal = 0
        this.detail.orderItems.forEach((x) => {
          subtotal += x.quantity * x.purchase_price
        })

        return subtotal
      }
    }
  }
}
</script>

<style lang="scss">
.orderHistory-info-divider {
  border-top: 2px solid #d5dde0;
}

@media only screen and (max-width: 599px) {
  .history-table-left-info {
    width: 110px;
    &.product-image {
      width: 200px;
    }
  }
  .history-product-image-box {
    width: 100%;
  }
}
</style>
