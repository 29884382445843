var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderHistory-box rounded-xl py-4 my-4" },
    [
      _c(
        "v-row",
        {
          staticClass: "px-4",
          attrs: { justify: "space-between", "no-gutters": "" },
        },
        [
          _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
            _c("table", { staticClass: "orderHistory-info-table d-block" }, [
              _c("tr", { staticClass: "d-sm-table-row d-block" }, [
                _c(
                  "td",
                  {
                    staticClass:
                      "history-table-left-info d-sm-table-cell d-block",
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-0 text-sm-body-1 text-body-2 text_light_gray--text",
                      },
                      [_vm._v(_vm._s(_vm.$t("label.orderId")))]
                    ),
                  ]
                ),
                _c("td", { staticClass: "d-sm-table-cell d-block" }, [
                  _c("p", { staticClass: "mb-0 text-sm-body-1 text-body-2" }, [
                    _vm._v(_vm._s(_vm.detail.orderReferenceNumber)),
                  ]),
                ]),
              ]),
              _c("tr", { staticClass: "d-sm-table-row d-block" }, [
                _c(
                  "td",
                  {
                    staticClass:
                      "history-table-left-info d-sm-table-cell d-block",
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-0 text-sm-body-1 text-body-2 text_light_gray--text",
                      },
                      [_vm._v(_vm._s(_vm.$t("label.orderDate")))]
                    ),
                  ]
                ),
                _c("td", { staticClass: "d-sm-table-cell d-block" }, [
                  _c("p", { staticClass: "mb-0 text-sm-body-1 text-body-2" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$shared.getDateFormat(_vm.detail.createdDateTime)
                      )
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
            _c(
              "table",
              {
                staticClass:
                  "orderHistory-info-table text-sm-right text-center",
              },
              [
                _c("tr", [
                  _c("td", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "mb-0 py-2 my-sm-0 text-sm-body-1 text-body-2 font-weight-bold unbox_primary--text text-capitalize",
                      },
                      [_vm._v(" " + _vm._s(_vm.detail.orderStatus) + " ")]
                    ),
                  ]),
                ]),
                _c("tr", [_c("td")]),
              ]
            ),
          ]),
        ],
        1
      ),
      _c("v-divider", { staticClass: "my-4 orderHistory-info-divider" }),
      _vm._l(_vm.detail.orderItems, function (orderItem, index) {
        return _c(
          "v-row",
          {
            key:
              _vm.detail.referenceNumber +
              "_" +
              index +
              "_" +
              orderItem.product_display_sku,
            staticClass: "px-4",
            class: _vm.isPayment ? "" : index >= 1 ? "d-none" : "",
            attrs: { justify: "space-between", "no-gutters": "" },
          },
          [
            _c(
              "v-col",
              {
                staticClass: "text-sm-left text-center",
                attrs: { cols: "12", sm: "auto" },
              },
              [
                _c("table", { staticClass: "orderHistory-info-table" }, [
                  _c("tr", [
                    _c(
                      "td",
                      {
                        staticClass:
                          "history-table-left-info product-image d-block d-sm-table-cell mx-auto mr-sm-0",
                      },
                      [
                        _c("img", {
                          staticClass:
                            "full-width history-product-image-box rounded-lg",
                          attrs: {
                            src:
                              _vm.shared.MEDIA_SERVER_URL +
                              "/products/variants/" +
                              orderItem.product_display_sku +
                              ".png",
                            alt:
                              orderItem.product_name +
                              " " +
                              _vm.getProductVariantDisplay(
                                orderItem.product_variants
                              ),
                            title:
                              orderItem.product_name +
                              " " +
                              _vm.getProductVariantDisplay(
                                orderItem.product_variants
                              ),
                          },
                        }),
                      ]
                    ),
                    _c("td", { staticClass: "d-block d-sm-table-cell" }, [
                      _c(
                        "p",
                        { staticClass: "text-body-1 font-weight-bold my-1" },
                        [_vm._v(_vm._s(orderItem.product_name))]
                      ),
                      _c(
                        "p",
                        { staticClass: "text-sm-body-1 text-body-2 my-1" },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getProductVariantDisplay(
                                  orderItem.product_variants
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "p",
                        {
                          staticClass:
                            "unbox_red--text font-weight-bold text-sm-body-1 text-body-2 my-1",
                        },
                        [
                          _vm._v(
                            _vm._s(
                              "" +
                                _vm.$shared.formatCurrency(
                                  orderItem.purchase_price +
                                    orderItem.mark_up_price
                                )
                            )
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
            _c(
              "v-col",
              {
                staticClass: "text-sm-left text-center",
                attrs: { cols: "12", sm: "auto" },
              },
              [
                _c("span", { staticClass: "text-sm-body-1 text-body-2" }, [
                  _vm._v(_vm._s(_vm.$t("label.quantity") + ": ")),
                ]),
                _c("span", { staticClass: "text-h6 font-weight-bold mb-2" }, [
                  _vm._v(_vm._s("x" + orderItem.quantity)),
                ]),
              ]
            ),
          ],
          1
        )
      }),
      _c("v-divider", { staticClass: "my-4 orderHistory-info-divider" }),
      _c(
        "v-row",
        {
          staticClass: "px-4",
          attrs: { justify: "space-between", "no-gutters": "" },
        },
        [
          _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
            _c("table", { staticClass: "orderHistory-info-table" }, [
              _c("tr", [
                _c("td", { staticClass: "history-table-left-info" }, [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-0 text-sm-body-1 text-body-2 text_light_gray--text",
                    },
                    [_vm._v(_vm._s(_vm.$t("label.totalItems")))]
                  ),
                ]),
                _c("td", { staticClass: "text-right text-sm-left" }, [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-0 text-sm-body-1 text-body-2 text_light_gray--text",
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.detail.orderItems.length +
                            " " +
                            _vm.$t("label.items")
                        )
                      ),
                    ]
                  ),
                ]),
              ]),
              _c("tr", [
                _c("td", { staticClass: "history-table-left-info" }, [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-0 text-sm-body-1 text-body-2 text_light_gray--text",
                    },
                    [_vm._v(_vm._s(_vm.$t("label.orderTotal")))]
                  ),
                ]),
                _c("td", { staticClass: "text-right text-sm-left" }, [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-0 unbox_red--text font-weight-bold text-sm-body-1 text-body-2",
                    },
                    [
                      _vm._v(
                        _vm._s(
                          "" +
                            _vm.$shared.formatCurrency(
                              _vm.detail.totalPrice + _vm.detail.markUpPrice
                            )
                        )
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
            _c("table", { staticClass: "orderHistory-info-table text-right" }, [
              _c("tr", [
                _c("td", [
                  !_vm.isPayment
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "text-capitalize rounded-lg d-none d-sm-inline-block",
                              attrs: {
                                outlined: "",
                                color: "#64748B",
                                dense: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.viewDetail(_vm.detail)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("label.viewDetails")))]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "font-weight-bold text-capitalize rounded-lg d-sm-none text-md-h6 text-body-2 py-md-8 py-6",
                              attrs: {
                                height: "50",
                                outlined: "",
                                color: "#64748B",
                                block: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.viewDetail(_vm.detail)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("label.viewDetails")) + " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
              _c("tr", [
                _c("td", [
                  !_vm.isPayment && _vm.detail.receiptPath != null
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "d-none d-sm-inline-block backgroundColor--white",
                              attrs: { elevation: "0", tile: "", icon: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.getReceipt()
                                },
                              },
                            },
                            [
                              _c("v-icon", {
                                domProps: {
                                  textContent: _vm._s("$downloadIcon"),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "font-weight-bold text-capitalize rounded-lg d-sm-none text-md-h6 text-body-2 py-md-8 py-6 white--text",
                              attrs: {
                                block: "",
                                height: "50",
                                color: "black",
                                elevation: "0",
                                tile: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.getReceipt()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("label.downloadReceipt")) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }