var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { color: "transparent", tile: "", elevation: "0" } },
    [
      _c(
        "v-card",
        {
          staticClass: "py-sm-8 py-4",
          attrs: { color: "light_gray", tile: "", elevation: "0" },
        },
        [
          _c(
            "v-row",
            { attrs: { justify: "center", "no-gutters": "" } },
            [
              _c("v-col", { attrs: { cols: "11", lg: "9" } }, [
                _c(
                  "span",
                  { staticClass: "text-sm-h5 text-body-1 font-weight-bold" },
                  [_vm._v(_vm._s(_vm.$t("label.myOrders")))]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        { attrs: { color: "light_gray", tile: "", elevation: "0" } },
        [
          _c(
            "v-row",
            { attrs: { justify: "center", "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "11", lg: "9" } },
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: {
                        "center-active": "",
                        "background-color": "transparent",
                        color: "unbox_primary",
                      },
                      model: {
                        value: _vm.selectedHistoryTab,
                        callback: function ($$v) {
                          _vm.selectedHistoryTab = $$v
                        },
                        expression: "selectedHistoryTab",
                      },
                    },
                    _vm._l(_vm.historyTabs, function (tab) {
                      return _c("v-tab", { key: tab.value }, [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-capitalize unbox_black--text text-sm-body-1 text-body-2 font-weight-bold",
                          },
                          [_vm._v(_vm._s(tab.text))]
                        ),
                      ])
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.selectedHistoryTab == _vm.historyTabs.length - 2 &&
      _vm.isViewHistoryDetail == false
        ? _c(
            "v-row",
            {
              staticClass: "my-8",
              attrs: { justify: "center", "no-gutters": "" },
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "11", lg: "9" } },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "history-status-selection text-center",
                      attrs: { justify: "center", "no-gutters": "" },
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "py-3",
                          class:
                            _vm.selectedHistoryStatus == "processing"
                              ? "active"
                              : "",
                          attrs: { cols: "5" },
                          on: {
                            click: function ($event) {
                              _vm.selectedHistoryStatus = "processing"
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-inline-block font-weight-bold text-sm-body-1 text-body-2",
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("label.processing")) + " "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "py-3",
                          class:
                            _vm.selectedHistoryStatus == "completed"
                              ? "active"
                              : "",
                          attrs: { cols: "5" },
                          on: {
                            click: function ($event) {
                              _vm.selectedHistoryStatus = "completed"
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-inline-block font-weight-bold text-sm-body-1 text-body-2",
                            },
                            [_vm._v(_vm._s(_vm.$t("label.completed")))]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        { staticClass: "my-8", attrs: { justify: "center", "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "11", lg: "9" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "pa-6 px-sm-8 px-4 rounded-xl",
                  attrs: { color: "transparent", tile: "", elevation: "15" },
                },
                [
                  _vm.isViewHistoryDetail && _vm.historyDetail
                    ? _c(
                        "div",
                        {
                          staticClass: "history-detail-page pr-0 pr-sm-4 mb-6",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "mb-2" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "unbox_primary--text text-capitalize",
                                  attrs: {
                                    elevation: "0",
                                    ripple: false,
                                    text: "",
                                    depressed: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.isViewHistoryDetail = false
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", {
                                    staticClass: "stroke--unbox_primary mr-2",
                                    attrs: { small: "", left: "" },
                                    domProps: {
                                      textContent: _vm._s("$chevronLeftIcon"),
                                    },
                                  }),
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("label.back")) + " "
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.historyTabs[_vm.selectedHistoryTab].value !==
                          "history"
                            ? _c(
                                "div",
                                [
                                  _c("app-order-detail", {
                                    attrs: {
                                      detail: _vm.historyDetail,
                                      pickUpAddress:
                                        _vm.historyDetailOutletAddress,
                                      isCompletedTab:
                                        _vm.historyTabs[_vm.selectedHistoryTab]
                                          .value === "completed",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                [
                                  _c("app-refund-detail", {
                                    attrs: { detail: _vm.historyDetail },
                                  }),
                                ],
                                1
                              ),
                          _c(
                            "div",
                            { staticClass: "mt-10" },
                            [
                              _c(
                                "v-row",
                                { attrs: { justify: "center" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", sm: "5", md: "4" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "rounded-lg font-weight-bold text-lg-h6 text-body-2 py-6",
                                          attrs: {
                                            outlined: "",
                                            block: "",
                                            color: "black",
                                          },
                                          on: { click: _vm.contactUnbox },
                                        },
                                        [
                                          _c("v-icon", {
                                            staticClass: "stroke--black mr-2",
                                            domProps: {
                                              textContent:
                                                _vm._s("$whatappIcon"),
                                            },
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("label.contactUnbox")
                                              ) +
                                              " "
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.historyDetail.transactionType.toLowerCase() ==
                                    "preorder" &&
                                  _vm.historyDetail.orderStatus.toLowerCase() ==
                                    "success" &&
                                  _vm.historyDetail.linkReferenceNumber ==
                                    null &&
                                  _vm.historyTabs[_vm.selectedHistoryTab]
                                    .value !== "completed"
                                    ? _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "5",
                                            md: "4",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "rounded-lg font-weight-bold white--text text-lg-h6 text-body-2 py-6",
                                              attrs: {
                                                block: "",
                                                color: "unbox_red",
                                                height: "50",
                                              },
                                              on: {
                                                click: function () {
                                                  return _vm.clearBalanceNow(
                                                    _vm.historyDetail
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("label.clearBalance")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.historyDetail.orderStatus.toLowerCase() ==
                                  "pendingsubmission"
                                    ? _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12",
                                            sm: "5",
                                            md: "4",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass:
                                                "rounded-lg font-weight-bold white--text text-lg-h6 text-body-2 py-6",
                                              attrs: {
                                                block: "",
                                                color: "unbox_red",
                                                height: "50",
                                              },
                                              on: { click: _vm.goToPayNow },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("label.payNow")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    : _c("div", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "history-container unbox_scrollbar pr-sm-4 pr-0 mb-6",
                          },
                          [
                            _vm.orderHistory != null &&
                            _vm.orderHistory.length > 0
                              ? _c(
                                  "div",
                                  [
                                    _vm._l(
                                      _vm.historyTabs[_vm.selectedHistoryTab]
                                        .value == "toPay"
                                        ? _vm.orderHistory.filter(function (x) {
                                            return (
                                              x.platform.toLowerCase() != "pos"
                                            )
                                          })
                                        : _vm.historyTabs[
                                            _vm.selectedHistoryTab
                                          ].value == "history"
                                        ? _vm.orderHistory.filter(function (x) {
                                            return (
                                              x.platform.toLowerCase() !=
                                                "pos" &&
                                              (x.orderStatus.toLowerCase() !=
                                                "cancelled" ||
                                                x.orderStatus.toLowerCase() !=
                                                  "failed")
                                            )
                                          })
                                        : _vm.orderHistory,
                                      function (history) {
                                        return _c(
                                          "div",
                                          {
                                            key: history.uuid,
                                            staticClass: "mb-16",
                                          },
                                          [
                                            _vm.historyTabs[
                                              _vm.selectedHistoryTab
                                            ].value !== "history" ||
                                            (_vm.historyTabs[
                                              _vm.selectedHistoryTab
                                            ].value === "history" &&
                                              _vm.selectedHistoryStatus.toLowerCase() ==
                                                "processing")
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c("app-order-summary", {
                                                      attrs: {
                                                        detail: history,
                                                        openConfirmationDialog:
                                                          _vm.openConfirmationDialog,
                                                        viewDetail:
                                                          _vm.viewDetail,
                                                        isProcessingTab:
                                                          _vm.historyTabs[
                                                            _vm
                                                              .selectedHistoryTab
                                                          ].value ===
                                                            "history" &&
                                                          _vm.selectedHistoryStatus.toLowerCase() ==
                                                            "processing",
                                                        isCompletedTab:
                                                          _vm.historyTabs[
                                                            _vm
                                                              .selectedHistoryTab
                                                          ].value ===
                                                          "completed",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "div",
                                                  [
                                                    _c("app-refund-summary", {
                                                      attrs: {
                                                        detail: history,
                                                        openConfirmationDialog:
                                                          _vm.openConfirmationDialog,
                                                        viewDetail:
                                                          _vm.viewDetail,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                          ]
                                        )
                                      }
                                    ),
                                    _c("v-pagination", {
                                      attrs: {
                                        color: "unbox_primary",
                                        length:
                                          _vm.orderHistoryPagination.lastPage,
                                        "total-visible": 7,
                                      },
                                      on: { input: _vm.updatePage },
                                      model: {
                                        value:
                                          _vm.orderHistoryPagination.pageNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.orderHistoryPagination,
                                            "pageNumber",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "orderHistoryPagination.pageNumber",
                                      },
                                    }),
                                  ],
                                  2
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "mt-16 pt-16 mb-16 text-center",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-h5 d-block my-2 font-weight-bold",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("label.noOrderYet"))
                                        ),
                                      ]
                                    ),
                                    _c("div", { staticClass: "text-h6 my-2" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "label.orderUpdatesWillDisplayedHere"
                                          )
                                        )
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "mt-4 rounded-lg font-weight-bold text-body-1 py-6 white--text",
                                            attrs: {
                                              color: "black",
                                              "max-width": "300px",
                                            },
                                            on: { click: _vm.goToShoppingPage },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "label.continueShopping"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                          ]
                        ),
                      ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("cancel-order-dialog", {
        attrs: {
          "dialog-show": _vm.confirmationDialog,
          title: _vm.confirmationTitle,
          "on-close": function () {
            return (_vm.confirmationDialog = false)
          },
          "on-cancel": function () {
            return (_vm.confirmationDialog = false)
          },
          "confirmation-message": _vm.confirmationMessage,
          "confirmation-action": _vm.confirmationAction,
          "require-remark": _vm.requireRemark,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }