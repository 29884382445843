var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "orderHistory-box rounded-xl my-4" },
      [
        _c(
          "v-row",
          { attrs: { "no-gutters": "" } },
          [
            _c("v-col", { staticClass: "pa-4", attrs: { cols: "12" } }, [
              _c(
                "div",
                { staticClass: "font-weight-bold text-sm-body-1 text-body-2" },
                [_vm._v(" " + _vm._s(_vm.$t("label.yourItems")) + " ")]
              ),
            ]),
            _c(
              "v-col",
              { attrs: { cols: "12" } },
              [_c("v-divider", { staticClass: "orderHistory-info-divider" })],
              1
            ),
            _c(
              "v-col",
              { staticClass: "pa-4", attrs: { cols: "12" } },
              _vm._l(_vm.detail.orderItems, function (orderItem, index) {
                return _c(
                  "v-row",
                  {
                    key:
                      _vm.detail.referenceNumber +
                      "_" +
                      index +
                      "_" +
                      orderItem.product_display_sku,
                    attrs: { justify: "space-between", "no-gutters": "" },
                  },
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "text-sm-left text-center",
                        attrs: { cols: "12", sm: "auto" },
                      },
                      [
                        _c(
                          "table",
                          { staticClass: "orderHistory-info-table" },
                          [
                            _c("tr", [
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "history-table-left-info product-image d-block d-sm-table-cell mx-auto mr-sm-0",
                                },
                                [
                                  _c("img", {
                                    staticClass:
                                      "full-width history-product-image-box rounded-lg",
                                    attrs: {
                                      src:
                                        _vm.shared.MEDIA_SERVER_URL +
                                        "/products/variants/" +
                                        orderItem.product_display_sku +
                                        ".png",
                                      alt:
                                        orderItem.product_name +
                                        " " +
                                        _vm.getProductVariantDisplay(
                                          orderItem.product_variants
                                        ),
                                      title:
                                        orderItem.product_name +
                                        " " +
                                        _vm.getProductVariantDisplay(
                                          orderItem.product_variants
                                        ),
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "td",
                                { staticClass: "d-block d-sm-table-cell" },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "text-body-1 font-weight-bold my-1",
                                    },
                                    [_vm._v(_vm._s(orderItem.product_name))]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "text-sm-body-1 text-body-2 my-1",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getProductVariantDisplay(
                                              orderItem.product_variants
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "unbox_red--text font-weight-bold text-sm-body-1 text-body-2 my-1",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          "" +
                                            _vm.$shared.formatCurrency(
                                              orderItem.purchase_price +
                                                orderItem.mark_up_price
                                            )
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "v-col",
                      {
                        staticClass: "text-sm-left text-center",
                        attrs: { cols: "12", sm: "auto" },
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "text-sm-body-1 text-body-2" },
                          [_vm._v(_vm._s(_vm.$t("label.quantity") + ": "))]
                        ),
                        _c(
                          "span",
                          { staticClass: "text-h6 font-weight-bold mb-2" },
                          [_vm._v(_vm._s("x" + orderItem.quantity))]
                        ),
                      ]
                    ),
                  ],
                  1
                )
              }),
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      [
        _c("div", { staticClass: "px-4" }, [
          _c(
            "div",
            { staticClass: "font-weight-bold text-body-2" },
            [
              _c("v-icon", {
                staticClass: "stroke--unbox_primary text-body-1",
                domProps: { textContent: _vm._s("$paymentMethodIcon") },
              }),
              _vm._v(" " + _vm._s(_vm.$t("label.paymentMethod")) + " "),
            ],
            1
          ),
          _vm.detail.paymentInfo.length > 0
            ? _c("div", { staticClass: "mt-1 text-sm-body-1 text-body-2" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "paymentMethods." +
                          _vm.detail.paymentInfo[0].payment_method.toLowerCase()
                      )
                    ) +
                    " "
                ),
              ])
            : _c("div", { staticClass: "mt-1 text-sm-body-1 text-body-2" }, [
                _vm._v("-"),
              ]),
        ]),
        _c("v-divider", { staticClass: "my-2" }),
      ],
      1
    ),
    _c("div", { staticClass: "px-4" }, [
      _c("table", { staticClass: "orderHistory-info-table" }, [
        _c("tr", [
          _c(
            "td",
            { staticClass: "history-table-left-info d-sm-table-cell d-block" },
            [
              _c(
                "p",
                {
                  staticClass:
                    "mb-0 text-sm-body-1 text-body-2 text_light_gray--text",
                },
                [_vm._v(_vm._s(_vm.$t("label.orderId")))]
              ),
            ]
          ),
          _c("td", { staticClass: "d-sm-table-cell d-block" }, [
            _c("p", { staticClass: "mb-0 text-sm-body-1 text-body-2" }, [
              _vm._v(_vm._s(_vm.detail.orderReferenceNumber)),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }