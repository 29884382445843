var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("app-dialog", {
    attrs: {
      dialogShow: _vm.dialogShow,
      title: _vm.title,
      closeAction: _vm.handleOnClose,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "confirmationForm-tab" },
              [
                _c(
                  "v-card",
                  { attrs: { color: "light_gray", elevation: "0" } },
                  [
                    _c(
                      "v-form",
                      { ref: "refundCancelOrderForm" },
                      [
                        _c(
                          "v-row",
                          [
                            _c("v-col", { attrs: { cols: "12" } }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-body-2 unbox_secondary--text",
                                },
                                [_vm._v(_vm._s(_vm.confirmationMessage))]
                              ),
                            ]),
                            _vm.requireRemark
                              ? [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("app-form-field", {
                                        attrs: {
                                          type: "select",
                                          placeholder: _vm.$t(
                                            "label.cancellationReason"
                                          ),
                                          items: _vm.returnTypes,
                                          rules:
                                            _vm.$validation.requiredRules(),
                                        },
                                        model: {
                                          value: _vm.returnType,
                                          callback: function ($$v) {
                                            _vm.returnType = $$v
                                          },
                                          expression: "returnType",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.isRemark
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("app-form-field", {
                                            attrs: {
                                              type: "textarea",
                                              placeholder: _vm.$t(
                                                "label.enterCancellationReason"
                                              ),
                                              rules:
                                                _vm.$validation.requiredRules(),
                                            },
                                            model: {
                                              value: _vm.remark,
                                              callback: function ($$v) {
                                                _vm.remark =
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                              },
                                              expression: "remark",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                            _c("v-col", { attrs: { cols: "12" } }, [
                              _c(
                                "div",
                                { staticClass: "text-center" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "white--text full-height text-body-1 rounded-lg font-weight-bold text-capitalize px-8",
                                      attrs: {
                                        block: "",
                                        height: "56px",
                                        color: "unbox_black",
                                        elevation: "0",
                                      },
                                      on: { click: _vm.handleOnSubmit },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("label.confirm")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "text-center" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass:
                                        "black--text full-height text-body-1 rounded-lg font-weight-bold text-capitalize px-8",
                                      attrs: {
                                        text: "",
                                        height: "56px",
                                        block: "",
                                        color: "unbox_black",
                                        elevation: "0",
                                      },
                                      on: { click: _vm.handleOnCancel },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("label.cancel")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }