var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "px-4" }, [
        _c(
          "div",
          { staticClass: "font-weight-bold text-body-2" },
          [
            _c("v-icon", {
              staticClass: "stroke--unbox_primary text-body-1",
              domProps: { textContent: _vm._s("$deliveryTruckIcon") },
            }),
            _vm._v(" " + _vm._s(_vm.$t("label.deliveryMethod")) + " "),
          ],
          1
        ),
        _c("div", { staticClass: "mt-1 text-sm-body-1 text-body-2" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.detail.deliveryMethodName != null
                  ? _vm.$t(
                      "deliveryMethods." +
                        _vm.detail.deliveryMethodName.toLowerCase()
                    )
                  : "-"
              ) +
              " "
          ),
        ]),
      ]),
      _c("v-divider", { staticClass: "my-4 orderHistory-info-divider" }),
      _c(
        "div",
        { staticClass: "px-4" },
        [
          _c(
            "div",
            { staticClass: "font-weight-bold text-body-2" },
            [
              _c("v-icon", {
                staticClass: "stroke--unbox_primary text-body-1",
                domProps: { textContent: _vm._s("$deliveryTruckIcon") },
              }),
              _vm._v(" " + _vm._s(_vm.$t("label.trackingNumber")) + " "),
            ],
            1
          ),
          _vm.detail.trackingNumber != null
            ? _c(
                "a",
                {
                  staticClass: "mt-1 text-sm-body-1 text-body-2",
                  attrs: {
                    href:
                      "https://t.17track.net/en#nums=" +
                      _vm.detail.trackingNumber,
                    target: "_blank",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.detail.courierName +
                          " - " +
                          _vm.detail.trackingNumber
                      ) +
                      " "
                  ),
                ]
              )
            : _c("div", { staticClass: "mt-1 text-sm-body-1 text-body-2" }, [
                _vm._v("-"),
              ]),
          _vm.detail.trackingNumber != null
            ? _c(
                "v-btn",
                {
                  staticClass: "backgroundColor--white",
                  attrs: { elevation: "0", tile: "", icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.copyTrackingNumber()
                    },
                  },
                },
                [
                  _c("v-icon", {
                    staticClass: "stroke--light_gray",
                    domProps: { textContent: _vm._s("$copyIcon") },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("v-divider", { staticClass: "my-4 orderHistory-info-divider" }),
      _vm.detail.deliveryMethodName != null
        ? _c(
            "div",
            { staticClass: "px-4" },
            [
              _c(
                "div",
                { staticClass: "font-weight-bold text-body-2" },
                [
                  _c("v-icon", {
                    staticClass: "stroke--unbox_primary text-body-1",
                    domProps: { textContent: _vm._s("$locationIcon") },
                  }),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.detail.deliveryMethodName.toLowerCase() ==
                          _vm.deliveryMethod.SELF_PICKUP
                          ? _vm.$t("label.pickUpAddress")
                          : _vm.$t("label.deliveryAddress")
                      ) +
                      " "
                  ),
                ],
                1
              ),
              _vm.detail.deliveryMethodName.toLowerCase() ==
              _vm.deliveryMethod.SELF_PICKUP
                ? _c(
                    "div",
                    { staticClass: "mt-1 text-sm-body-1 text-body-2" },
                    [
                      _c("div", [
                        _vm._v(_vm._s(_vm.pickUpAddress.externalName)),
                      ]),
                      _c("div", [_vm._v(_vm._s(_vm.pickUpAddress.addressOne))]),
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.pickUpAddress.addressTwo) +
                            " " +
                            _vm._s(_vm.pickUpAddress.addressThree)
                        ),
                      ]),
                      _c("div", [
                        _vm._v(
                          _vm._s(_vm.pickUpAddress.postcode) +
                            " " +
                            _vm._s(_vm.pickUpAddress.city) +
                            " " +
                            _vm._s(_vm.pickUpAddress.state)
                        ),
                      ]),
                    ]
                  )
                : _c(
                    "div",
                    { staticClass: "mt-1 text-sm-body-1 text-body-2" },
                    [
                      _c("div", [
                        _vm._v(_vm._s(_vm.detail.emailPostageCustomerName)),
                      ]),
                      _c("div", [
                        _vm._v(_vm._s(_vm.detail.emailPostagePhoneNumber)),
                      ]),
                      _c("div", [
                        _vm._v(_vm._s(_vm.detail.emailPostageAddress)),
                      ]),
                    ]
                  ),
              _c("v-divider", {
                staticClass: "my-4 orderHistory-info-divider",
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "px-4" }, [
        _c(
          "div",
          { staticClass: "font-weight-bold text-body-2" },
          [
            _c("v-icon", {
              staticClass: "stroke--unbox_primary text-body-1",
              domProps: { textContent: _vm._s("$billingIcon") },
            }),
            _vm._v(" " + _vm._s(_vm.$t("label.billingAddress")) + " "),
          ],
          1
        ),
        _c("div", { staticClass: "mt-1 text-sm-body-1 text-body-2" }, [
          _c("div", [_vm._v(_vm._s(_vm.detail.emailCustomerName))]),
          _c("div", [_vm._v(_vm._s(_vm.detail.emailPhoneNumber))]),
          _c("div", [_vm._v(_vm._s(_vm.detail.emailBillingAddress))]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "orderHistory-box rounded-xl my-4" },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("v-col", { staticClass: "pa-4", attrs: { cols: "12" } }, [
                _c(
                  "div",
                  {
                    staticClass: "font-weight-bold text-sm-body-1 text-body-2",
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("label.yourItems")) + " ")]
                ),
              ]),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [_c("v-divider", { staticClass: "orderHistory-info-divider" })],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pa-4", attrs: { cols: "12" } },
                _vm._l(
                  _vm.isCompletedTab
                    ? _vm.detail.orderItems.filter(function (item) {
                        return (
                          item.order_statuses_name.toLowerCase() != "exchanged"
                        )
                      })
                    : _vm.detail.orderItems,
                  function (orderItem, index) {
                    return _c(
                      "v-row",
                      {
                        key:
                          _vm.detail.referenceNumber +
                          "_" +
                          index +
                          "_" +
                          orderItem.product_display_sku,
                        attrs: { justify: "space-between", "no-gutters": "" },
                      },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "text-sm-left text-center",
                            attrs: { cols: "12", sm: "auto" },
                          },
                          [
                            _c(
                              "table",
                              { staticClass: "orderHistory-info-table" },
                              [
                                _c("tr", [
                                  _c(
                                    "td",
                                    {
                                      staticClass:
                                        "history-table-left-info product-image d-block d-sm-table-cell mx-auto mr-sm-0",
                                    },
                                    [
                                      _c("img", {
                                        staticClass:
                                          "full-width history-product-image-box rounded-lg",
                                        attrs: {
                                          src:
                                            _vm.shared.MEDIA_SERVER_URL +
                                            "/products/variants/" +
                                            orderItem.product_display_sku +
                                            ".png",
                                          alt:
                                            orderItem.product_name +
                                            " " +
                                            _vm.getProductVariantDisplay(
                                              orderItem.product_variants
                                            ),
                                          title:
                                            orderItem.product_name +
                                            " " +
                                            _vm.getProductVariantDisplay(
                                              orderItem.product_variants
                                            ),
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "td",
                                    { staticClass: "d-block d-sm-table-cell" },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-body-1 font-weight-bold my-1",
                                        },
                                        [_vm._v(_vm._s(orderItem.product_name))]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "text-sm-body-1 text-body-2 my-1",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.getProductVariantDisplay(
                                                  orderItem.product_variants
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "unbox_red--text font-weight-bold text-sm-body-1 text-body-2 my-1",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              "" +
                                                _vm.$shared.formatCurrency(
                                                  orderItem.selling_price
                                                )
                                            )
                                          ),
                                        ]
                                      ),
                                      Array.isArray(
                                        orderItem.service_extra_info
                                      )
                                        ? [
                                            _vm._l(
                                              orderItem.service_extra_info.flatMap(
                                                Object.entries
                                              ),
                                              function (info) {
                                                return [
                                                  _c(
                                                    "label",
                                                    {
                                                      key: info[0] + info[1],
                                                      staticClass:
                                                        "text-body-2 font-weight-medium d-block",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(info[0]) +
                                                          " : " +
                                                          _vm._s(info[1])
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              }
                                            ),
                                          ]
                                        : orderItem.service_extra_info
                                        ? [
                                            _vm._l(
                                              Object.keys(
                                                orderItem.service_extra_info
                                              ),
                                              function (extraInfoCategory) {
                                                return [
                                                  orderItem.service_extra_info[
                                                    extraInfoCategory
                                                  ]
                                                    ? _c(
                                                        "label",
                                                        {
                                                          key:
                                                            "service" +
                                                            index +
                                                            "-" +
                                                            extraInfoCategory,
                                                          staticClass:
                                                            "text-body-2 font-weight-medium d-block",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.toStartCase(
                                                                  extraInfoCategory
                                                                )
                                                              ) +
                                                              " : " +
                                                              _vm._s(
                                                                orderItem
                                                                  .service_extra_info[
                                                                  extraInfoCategory
                                                                ]
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              }
                                            ),
                                          ]
                                        : _vm._e(),
                                      orderItem.service_pin
                                        ? _c(
                                            "p",
                                            {
                                              staticClass:
                                                "text-body-2 font-weight-medium",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("label.pin")) +
                                                  ": " +
                                                  _vm._s(
                                                    orderItem.service_pin
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "ml-2 backgroundColor--white",
                                                  attrs: {
                                                    small: "",
                                                    elevation: "0",
                                                    tile: "",
                                                    icon: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.copyServicePin(
                                                        orderItem.service_pin
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("v-icon", {
                                                    staticClass:
                                                      "stroke--light_gray",
                                                    attrs: { small: "" },
                                                    domProps: {
                                                      textContent:
                                                        _vm._s("$copyIcon"),
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "text-sm-left text-center",
                            attrs: { cols: "12", sm: "auto" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "text-sm-body-1 text-body-2" },
                              [_vm._v(_vm._s(_vm.$t("label.quantity") + ": "))]
                            ),
                            _c(
                              "span",
                              { staticClass: "text-h6 font-weight-bold mb-2" },
                              [_vm._v(_vm._s("x" + orderItem.quantity))]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  }
                ),
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [_c("v-divider", { staticClass: "orderHistory-info-divider" })],
                1
              ),
              _c("v-col", { staticClass: "pa-4", attrs: { cols: "12" } }, [
                _c("table", { staticClass: "orderHistory-info-table" }, [
                  _c("tr", [
                    _c("td", { staticClass: "history-table-left-info" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-0 text-sm-body-1 text-body-2 text_light_gray--text",
                        },
                        [_vm._v(_vm._s(_vm.$t("label.subtotal")))]
                      ),
                    ]),
                    _c("td", { staticClass: "text-right text-sm-left" }, [
                      _c(
                        "p",
                        { staticClass: "mb-0 text-sm-body-1 text-body-2" },
                        [
                          _vm._v(
                            _vm._s(
                              "" +
                                _vm.$shared.formatCurrency(
                                  _vm.calculateSubtTotal()
                                )
                            )
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "history-table-left-info" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-0 text-sm-body-1 text-body-2 text_light_gray--text",
                        },
                        [_vm._v(_vm._s(_vm.$t("label.shippingFee")))]
                      ),
                    ]),
                    _c("td", { staticClass: "text-right text-sm-left" }, [
                      _c(
                        "p",
                        { staticClass: "mb-0 text-sm-body-1 text-body-2" },
                        [
                          _vm._v(
                            _vm._s(
                              "" +
                                _vm.$shared.formatCurrency(
                                  _vm.detail.shippingFee
                                )
                            )
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "history-table-left-info" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-0 text-sm-body-1 text-body-2 text_light_gray--text",
                        },
                        [_vm._v(_vm._s(_vm.$t("label.promoCode")))]
                      ),
                    ]),
                    _c("td", { staticClass: "text-right text-sm-left" }, [
                      _c(
                        "p",
                        { staticClass: "mb-0 text-sm-body-1 text-body-2" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.detail.discountCode != null
                                ? _vm.detail.discountCode
                                : "-"
                            )
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "history-table-left-info" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-0 text-sm-body-1 text-body-2 text_light_gray--text",
                        },
                        [_vm._v(_vm._s(_vm.$t("label.discount")))]
                      ),
                    ]),
                    _c("td", { staticClass: "text-right text-sm-left" }, [
                      _c(
                        "p",
                        { staticClass: "mb-0 text-sm-body-1 text-body-2" },
                        [
                          _vm._v(
                            _vm._s(
                              "- " +
                                _vm.$shared.formatCurrency(
                                  _vm.detail.discountAmount
                                )
                            )
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "history-table-left-info" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-0 text-sm-body-1 text-body-2 text_light_gray--text",
                        },
                        [_vm._v(_vm._s(_vm.$t("label.surcharge")))]
                      ),
                    ]),
                    _c("td", { staticClass: "text-right text-sm-left" }, [
                      _c(
                        "p",
                        { staticClass: "mb-0 text-sm-body-1 text-body-2" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.detail.surchargePrice != null
                                ? "" +
                                    _vm.$shared.formatCurrency(
                                      _vm.detail.surchargePrice
                                    )
                                : "-"
                            )
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "history-table-left-info" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-0 text-sm-body-1 text-body-2 text_light_gray--text",
                        },
                        [_vm._v(_vm._s(_vm.$t("label.deposit")))]
                      ),
                    ]),
                    _c("td", { staticClass: "text-right text-sm-left" }, [
                      _c(
                        "p",
                        { staticClass: "mb-0 text-sm-body-1 text-body-2" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.detail.depositAmount != null ||
                                _vm.detail.depositAmount != 0
                                ? "- " +
                                    _vm.$shared.formatCurrency(
                                      _vm.detail.depositAmount
                                    )
                                : "-"
                            )
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "history-table-left-info" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-0 text-sm-body-1 text-body-2 text_light_gray--text",
                        },
                        [_vm._v(_vm._s(_vm.$t("label.inTotal")))]
                      ),
                    ]),
                    _c("td", { staticClass: "text-right text-sm-left" }, [
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-0 text-sm-body-1 text-body-2 unbox_red--text font-weight-bold",
                        },
                        [
                          _vm._v(
                            _vm._s(
                              "" +
                                _vm.$shared.formatCurrency(
                                  _vm.detail.purchasePrice -
                                    _vm.detail.depositAmount
                                )
                            )
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("div", { staticClass: "px-4" }, [
            _c(
              "div",
              { staticClass: "font-weight-bold text-body-2" },
              [
                _c("v-icon", {
                  staticClass: "stroke--unbox_primary text-body-1",
                  domProps: { textContent: _vm._s("$paymentMethodIcon") },
                }),
                _vm._v(" " + _vm._s(_vm.$t("label.paymentMethod")) + " "),
              ],
              1
            ),
            _vm.detail.paymentInfo.length > 0
              ? _c("div", { staticClass: "mt-1 text-sm-body-1 text-body-2" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "paymentMethods." +
                            _vm.detail.paymentInfo[0].payment_method.toLowerCase()
                        )
                      ) +
                      " "
                  ),
                ])
              : _c("div", { staticClass: "mt-1 text-sm-body-1 text-body-2" }, [
                  _vm._v("-"),
                ]),
          ]),
          _c("v-divider", { staticClass: "my-2" }),
        ],
        1
      ),
      _c("div", { staticClass: "px-4" }, [
        _c("table", { staticClass: "orderHistory-info-table" }, [
          _c("tr", [
            _c(
              "td",
              {
                staticClass: "history-table-left-info d-sm-table-cell d-block",
              },
              [
                _c(
                  "p",
                  {
                    staticClass:
                      "mb-0 text-sm-body-1 text-body-2 text_light_gray--text",
                  },
                  [_vm._v(_vm._s(_vm.$t("label.orderId")))]
                ),
              ]
            ),
            _c("td", { staticClass: "d-sm-table-cell d-block" }, [
              _c("p", { staticClass: "mb-0 text-sm-body-1 text-body-2" }, [
                _vm._v(_vm._s(_vm.detail.orderReferenceNumber)),
              ]),
            ]),
          ]),
        ]),
        _vm.detail.orderStatusHistory.length > 0
          ? _c(
              "table",
              { staticClass: "orderHistory-info-table" },
              _vm._l(_vm.detail.orderStatusHistory, function (orderHistory) {
                return _c("tr", { key: orderHistory.uuid }, [
                  _c(
                    "td",
                    {
                      staticClass:
                        "history-table-left-info d-sm-table-cell d-block",
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "mb-0 text-sm-body-1 text-body-2 text_light_gray--text",
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t(
                                "historyOrderStatusTimeLine." +
                                  orderHistory.to_status.toLowerCase()
                              )
                            )
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c("td", { staticClass: "d-sm-table-cell d-block" }, [
                    _c(
                      "p",
                      { staticClass: "mb-0 text-sm-body-1 text-body-2" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$shared.getDateFormat(
                              orderHistory.updated_date_time
                            )
                          )
                        ),
                      ]
                    ),
                  ]),
                ])
              }),
              0
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }